import {Contract} from 'near-api-js';

export const burnNFT = async (account, token_id, contractID) => {
    const nftContract = new Contract(
        account,
        contractID,
        {
            changeMethods: ['nft_burn'],
            sender: account,
        }
    );
    await nftContract.nft_burn({
        callbackUrl: window.location.href.split("?")[0] + '?burn=true&',
        args: {
            token_id,
        },
        gas: 300000000000000,
        amount: 1
    });
    
    return 'success';
};