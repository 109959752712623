import * as nearAPI from 'near-api-js';
export const {utils: {format: {formatNearAmount, parseNearAmount}}} = nearAPI;

export const nftMintFromContract = async (account, deposit, quantity, wlBalance, mintBalance, metadata, contractID, isWhitelistMint, isPublicMint, wl_type) => {

    // Validate quantity 
    if(typeof quantity != 'number' || quantity <= 0)
        return "Invalid mint quantity";

    // Check max min per transaction 
    if(quantity > metadata.max_mint_per_transaction)
        return "Max mint per transaction is " + metadata.max_mint_per_transaction;

    // Check if user have reach max mint per wallet 
    if(metadata.max_mint_per_wallet - mintBalance < quantity)
        return "Max mint per wallet is " + metadata.max_mint_per_wallet;
   
	// If is public mint 
	let amount = 0;
	if(isPublicMint && !wl_type) {

        // Get mint amount 
		amount = (parseFloat(formatNearAmount(metadata.mint_price)) + parseFloat(formatNearAmount(metadata.token_storage_cost))) * quantity;

	} 
    // If is whitelist mint 
    else 
    {
        if(wl_type == 2) {
            amount = parseFloat(formatNearAmount(metadata.token_storage_cost)) ;
        }
        else 
        {
            // Check whitelist access
            if(wlBalance < quantity)
                return "You have reached the max amount of whitelist mint";
    
            // Get mint amount 
            amount = (parseFloat(formatNearAmount(metadata.wl_mint_price)) + parseFloat(formatNearAmount(metadata.token_storage_cost))) * quantity;
        }
	}
        
    // Validate account balance
    let checkDeposit = formatNearAmount(deposit.available);
    if(amount > checkDeposit) 
        return "Acccount balance not enough";

	// Mint
	let gas = (25000000000000 * (quantity + 1)).toString();
    const nftContract = new nearAPI.Contract(
        account,
        contractID,
        {
            changeMethods: ['nft_mint'],
            sender: account,
        }
    );

    await nftContract.nft_mint({
        callbackUrl: window.location.href.split("?")[0] + '?mint=true&',
        args: {
            quantity,
            wl_type
        },
        gas: gas,
        amount: parseNearAmount(amount.toString())
    });
    
    return 'success';
   
};