import React, { lazy, Suspense} from "react";
import '../style/launchpad.css';
import store from "../store/store.js";
import { Provider } from "react-redux";
import { useParams } from "react-router-dom";

import Content from "../components/Sections/Project/Content";
import TopNav from "../components/Nav/TopNavbar";

export default function Project(props) {

    return (
        <Suspense  fallback={<div id="screenLoader">Loading...</div>}>
            <Provider store={store}>                
                <TopNav/>
                <Content/>
            </Provider>
        </Suspense>
    );
}


