import { configureStore } from '@reduxjs/toolkit'
import connectionReducer from './reducers/connectionReducer'

const store = configureStore({
	reducer: connectionReducer,
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store